<template>
  <div id="department" class="h-100">
    <SetupTitle name="敏感詞管理" />
    <!-- main content -->
    <div class="setupMainContent">
      <v-tabs v-model="tab" color="#2F88FF">
        <v-tab value="customerTab" @click="switchTab">
          訪客敏感詞
        </v-tab>
        <v-tab value="agentTab" @click="switchTab">
          座席敏感詞
        </v-tab>
      </v-tabs>

      <v-card-text class="ps-0 pe-0">
        <v-window v-model="tab">
          <!-- 成員 -->
          <v-window-item value="customerTab">
            <div id="toolBar">
              <SetupSearchBar

                :is-show-search-input="true"
                :is-show-multiple-select="false"
                @searchSubmitFn="searchSensitive"
              />
              <CommonButtonAdd
                name="新增敏感詞"
                @click="showSensitiveDlg()"
              />
            </div>
            <div id="userTable">
              <LazySetupSensitiveTable
                :table-data="agentSensitiveList"

                @editSensitive="editSensitiveDlg"
                @deleteSensitive="deleteSensitive"
                @refreshSensitive="refreshSensitive"
              />
            </div>
          </v-window-item>
          <!-- 部門 -->
          <v-window-item value="agentTab">
            <div id="toolBar">
              <SetupSearchBar
                :is-show-search-input="true"
                :is-show-multiple-select="false"
                @searchSubmitFn="searchSensitive"
              />
              <CommonButtonAdd
                name="新增敏感詞"
                @click="showSensitiveDlg()"
              />
            </div>
            <div id="userTable">
              <LazySetupSensitiveTable
                :table-data="agentSensitiveList"
                @editSensitive="editSensitiveDlg"
                @deleteSensitive="deleteSensitive"
                @refreshSensitive="refreshSensitive"
              />
            </div>
          </v-window-item>
        </v-window>
        <LazySetupSensitiveAddEditDlg
          :is-show="dlgSensitiveVisible"
          :dialog-info="sensitiveInfo"
          @closeDlg="closeSensitiveDlg"
        />
      </v-card-text>
    </div>
  </div>
</template>

<script setup>

import { addSensitiveFn, getSensitivesFn, editSensitiveFn, deleteSensitiveFn } from '../../../api/message/sensitive'

const tab = ref('customerTab')

const agentSensitiveList = ref([])

const agentSearchInput = ref('')
const customerSearchInput = ref('')

const searchSensitive = (keyword) => {
  refreshSensitive(1, keyword.searchInput)
}

onBeforeMount(() => {
  currentPage.value = 1
  refreshSensitive()
})
// 【敏感詞】 -------------------------------------------------
const dlgSensitiveVisible = ref(false)
const sensitiveInfo = reactive({
  dlg: {
    type: 'add',
    userType: tab.value === 'customerTab' ? 'customer' : 'agent'
  },
  data: {
    id: '',
    word: '',
    status: '',
    type: ''
  }
})
// 切換Tab事件
const switchTab = () => {
  sensitiveInfo.dlg.userType = tab.value === 'customerTab' ? 'customer' : 'agent'
  refreshSensitive(1)
  currentPage.value = 1
}

// 顯示 add / edit Dlg
const showSensitiveDlg = () => {
  sensitiveInfo.dlg.type = 'add'
  sensitiveInfo.data = {
    id: '',
    word: '',
    status: '',
    type: ''
  }
  dlgSensitiveVisible.value = true
}

// 關閉 dlg
const closeSensitiveDlg = () => {
  dlgSensitiveVisible.value = false
  refreshSensitive()
}

// 切換敏感詞啟用狀態
const changeSensitiveEnabled = (item) => {
  const body = {
    id: item.id,
    status: item.status
  }
  editSensitive(body)
}

// 編輯資料
const editSensitiveDlg = (item) => {
  sensitiveInfo.dlg.type = 'edit'
  sensitiveInfo.data = item
  dlgSensitiveVisible.value = true
}

// 編輯敏感詞
const editSensitive = (item) => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      refreshSensitive()
    }
  }
  editSensitiveFn(item.id, item, onRes)
}

// 刪除敏感詞
const deleteSensitive = (item) => {
  const onRes = ({ response }) => {
    const res = response._data
    if (res.code === 200) {
      refreshSensitive()
    }
  }
  deleteSensitiveFn(item.id, onRes)
}
const currentPage = ref(1)

// 刷新敏感詞
const refreshSensitive = async (page, search) => {
  currentPage.value = page || currentPage.value

  if (tab.value === 'customerTab') {
    customerSearchInput.value = search !== undefined ? search : customerSearchInput.value
  } else {
    agentSearchInput.value = search !== undefined ? search : agentSearchInput.value
  }

  const params = {
    pageNum: currentPage.value,
    pageSize: 20,
    type: tab.value === 'customerTab' ? 'customer' : 'agent',
    word: tab.value === 'customerTab' ? customerSearchInput.value || '' : agentSearchInput.value || ''
  }

  const onRes = ({ response }) => {
    const res = response._data

    if (res.code === 200) {
      agentSensitiveList.value = res.data
    }
  }

  await getSensitivesFn(params, onRes)
}

provide('provideInfo', {
  refreshSensitive,
  changeSensitiveEnabled

})

</script>

  <style lang="scss" scoped>
    @import "assets/styles/pages/setup.scss";
    #toolBar {
      display: flex; align-items: center; justify-content: space-between;
    }
    :deep(.v-slide-group__content) {
      border-bottom: 1px solid #ccc;
    }
    :deep(.v-table) {
      height: calc(100% - 60px);
      overflow: scroll;
    }
    :deep(.v-table__wrapper) {
      height: 100% !important;
    }

    #userTable, #deptTable {
      height: calc(100vh - 257px);
    }

    #deptDetailBlock { // 部門詳情
      position: absolute; top: 0; right: 0px; z-index: 9;
      width: 750px; height: 100%;
      background: #fff;
      border: 1px solid #fbfbfb;
      box-shadow: -5px 0 5px rgba(223, 223, 223, 0.5);
      padding: 20px 30px;
    }
  </style>
