import { useRuntimeConfig } from '#app'
import { combineUrlWithParams } from '~/utils/httpUtils'

// 敏感詞控制器
const { useCustomFetch } = useFetchBase()

const base = '/api/message/'

const module = '/sensitive-words'

const path = base + module
// request(REQ) Api  --------------------------------
const getSensitives = path // 獲取敏感詞列表

const addSensitive = path // 添加敏感詞

const sensitive = path + '/{0}' // 編輯敏感詞

/**
 * 獲取敏感詞列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 分頁數據
 */
const getSensitivesFn = (params, callback) => { // 獲取敏感詞列表
  // check params.word not undefined
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(path, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}
/**
 * 添加敏感詞
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
const addSensitiveFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addSensitive, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 編輯敏感詞
 * @param {String} id
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
const editSensitiveFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(sensitive, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除敏感詞
 * @param {String} id 敏感詞id
 * @param {String} tenantId 租戶id
 * @param {Object} callback 回掉函數
 * @returns
 */
const deleteSensitiveFn = (id, callback) => { // 刪除敏感詞
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(sensitive, id)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}

export {

  getSensitivesFn,
  addSensitiveFn,
  editSensitiveFn,
  deleteSensitiveFn
}
